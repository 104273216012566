/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import { useEffect, useState } from 'react';
// @mui
import { Stack, Button, Typography, Box, Grid, TextField } from '@mui/material';
// components
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from '@mui/icons-material';
import RemoveIcon from "@mui/icons-material/Remove";
import AttachmentIcon from '@mui/icons-material/Attachment';

// sections
import AddDocument from './Add'
import EditDocument from './Edit'
import { deleteManyApi, apiget } from '../../../service/api';
import TableStyleTwo from '../../../components/TableStyleTwo';
import DeleteModel from '../../../components/Deletemodle'
import formatNumber from '../../../components/AmountFormatter'
// ----------------------------------------------------------------------

const PaymentPlan = ({ rows, toggleVisibilityPaymentPlan, fetchdata, isVisiblePaymentPlan, setUserAction, _id }) => {


    const userRole = localStorage.getItem("userRole");
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [paymentPlanViewData, setPaymentPlanViewData] = useState({});
    const [openDelete, setOpenDelete] = useState(false);
    const [searchText, setSearchText] = useState(''); // Search input state
    const [filteredRows, setFilteredRows] = useState(rows); // Filtered rows state
    const [loading, setLoading] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [editId, setEditId] = useState(null);

    const [openView, setOpenView] = useState(false);
    const handleOpenView = () => setOpenView(true);


    const handleOpenDelete = () => setOpenDelete(true);
    const handleCloseDelete = () => setOpenDelete(false);

    const handleOpenAdd = () => setOpenAdd(true);
    const handleCloseAdd = () => setOpenAdd(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);


    const deleteMany = async (deleteId) => {
        const result = await deleteManyApi('payment-plan/deletemany', deleteId)
        if (result && result.status === 200) {
            setUserAction(result);
            handleCloseDelete();

        }
    }



    const fetchSurgeryDocument = async (id) => {
        const result = await apiget(`payment-plan/view/${id}`);
        if (result && result.status === 200) {
            setPaymentPlanViewData(result?.data)

        }
    }

    const EditFileName = async (id) => {
        fetchSurgeryDocument(id)
        handleOpenEdit();

    }




    const columns = [

        {
            field: "dueAmount",
            headerName: "Due Amount (₹)",
            flex: 1,
            renderCell: (params) => (
                <Box>{formatNumber(params.value)}</Box>
            )
        },


        {
            field: "dueDate",
            headerName: "Due Date",
            flex: 1,
            valueFormatter: (params) => {
                const value = params.value;
                const date = value ? new Date(value) : null;
                if (date && !Number.isNaN(date.getTime())) {
                    return new Intl.DateTimeFormat('en-GB').format(date).replace(/\//g, '-');
                }
                return '--';
            },
        },
        {
            field: "desc",
            headerName: "Description",
            flex: 1,

        },
        {
            field: "status",
            headerName: "Payment Status",
            flex: 1,

        },

        {
            field: "action",
            headerName: "Action",
            flex: 1,
            maxWidth: 150,
            renderCell: (params) => {

                const handleDeleteDocument = (ID) => {
                    setSelectedId(ID)
                    handleOpenDelete();
                }

                return (

                    <Stack direction={"row"} spacing={2} alignItems={'center'} >

                        {userRole === 'admin' && <Delete variant='outlined' size='small' sx={{ cursor: 'pointer' }}
                            color='error' onClick={() => handleDeleteDocument(params.row._id)} />}

                        {userRole === 'admin' && <EditIcon variant='outlined' size='small' sx={{ cursor: 'pointer' }}
                            color='primary' onClick={() => {
                                EditFileName(params.row._id);
                                setEditId(params.row._id)
                            }} />
                        }

                    </Stack>

                );
            }
        },

    ];


    useEffect(() => {
        if (searchText.trim() === '') {
            setFilteredRows(rows);
        } else {
            const filteredData = rows && rows.filter((row) => {
                setLoading(true);

                return (
                    row.status?.toLowerCase().startsWith(searchText.toLowerCase())
                );
            });

            setFilteredRows(filteredData);
            setLoading(false);
        }
    }, [searchText, rows]);

    return (
        <>

            <EditDocument fetchdata={fetchdata} open={openEdit} handleClose={handleCloseEdit} setUserAction={setUserAction} id={editId} data={paymentPlanViewData} />
            {/* Add Document Model */}
            <DeleteModel opendelete={openDelete} handleClosedelete={handleCloseDelete} deletedata={deleteMany} id={[selectedId]} />
            {/* <View open={openView} setOpen={setOpenView} setUserAction={setUserAction} data={paymentPlanViewData} header="Payment Plan " /> */}
            <AddDocument open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} _id={_id} />

            <Box p={2} style={{ cursor: "pointer" }}>
                <Grid container display="flex" alignItems="center">
                    <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            <Button
                                onClick={toggleVisibilityPaymentPlan}
                                color="secondary"
                                variant="contained"
                                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px" }}
                            >
                                {isVisiblePaymentPlan ? <RemoveIcon /> : <AddIcon />}
                            </Button>
                            <AttachmentIcon />
                            <Typography variant="h5">Payment Plan</Typography>
                            {isVisiblePaymentPlan && <TextField
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                placeholder="Search..."
                                variant="outlined"
                                size="small"
                                style={{ marginBottom: '10px' }}
                            />}
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
                            {isVisiblePaymentPlan && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    startIcon={<AddIcon />}
                                    onClick={handleOpenAdd}
                                >
                                    Add Plan
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </Grid>
            </Box>
            {
                isVisiblePaymentPlan &&
                <TableStyleTwo>
                    <Box width="100%" height="30vh">
                        <DataGrid
                            rows={filteredRows}
                            columns={columns}
                            getRowId={row => row._id}
                            columnHeaderHeight={40}
                            loading={loading}
                        />
                    </Box>
                </TableStyleTwo>

            }
        </>
    );
}

export default PaymentPlan