/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Select, FormControl, MenuItem, TextField, Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// components
import { useEffect, useState } from 'react';
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
  AppPaymentExpenses,
  AppPaymentPlans,
  AppExistingPolicy
} from '../sections/@dashboard/app';
import { apiget } from '../service/api';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();

  const [totalLead, setTotalLead] = useState({
    high: 0,
    mid: 0,
    low: 0
  });


  // const [totalPolicy, setTotalPolicy] = useState(0);
  // const [totalEvent, setTotalEvent] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [duePolicy, setDuePolicy] = useState(0);
  const [activePolicy, setActivePolicy] = useState(0);
  const [renewedPolicy, setRenewedPolicy] = useState(0);
  const [expiryCount, setExpiryCount] = useState(0);
  const [taskCount, setTaskCount] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [calls, setCalls] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [chartLabelData, setChartLabelData] = useState([])
  const [totalPremiumDue, setTotalPremiumDue] = useState(0);
  const [totalPremiumNotRenewed, setTotalPremiumNotRenewed] = useState(0);
  const [totalPremiumExpired, setTotalPremiumExpired] = useState(0);
  const [totalPremiumActive, setTotalPremiumActive] = useState(0);
  const [duePaymentPlan, setDuePaymentPlan] = useState([]);
  const [existingCount,setExistingCount]=useState([]);

  const userid = localStorage.getItem('user_id');
  const userRole = localStorage.getItem("userRole");

  const [selectedOption, setSelectedOption] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [currentDate] = useState(new Date());

  const navigate = useNavigate();


  const handleOptionChange = (event) => {
    const { value } = event.target;
    setSelectedOption(value);

  };

  // Function to calculate and fetch data based on selection
  const fetchData = () => {
    let fromDate;

    // End date will always be the current date
    const toDate = currentDate.toISOString().split('T')[0];

    if (selectedOption) {
      const current = new Date();
      switch (selectedOption) {
        case 'Today':
          fromDate = toDate; // Today
          break;
        case 'Yesterday':
          fromDate = new Date(current.setDate(current.getDate() - 1)).toISOString().split('T')[0];
          break;
        case '1 Week':
          fromDate = new Date(current.setDate(current.getDate() - 7)).toISOString().split('T')[0];
          break;
        case '1 Month':
          fromDate = new Date(current.setMonth(current.getMonth() - 1)).toISOString().split('T')[0];
          break;
        case '6 Months':
          fromDate = new Date(current.setMonth(current.getMonth() - 6)).toISOString().split('T')[0];
          break;
        case 'custom':
          if (startDate) {
            fromDate = startDate.toISOString().split('T')[0]; // Custom date from calendar
          }
          break;
        default:
          fromDate = null;
      }
    }

    if (fromDate && toDate) {

      fetchLead(fromDate, toDate);
      getChartLabels(fromDate, toDate);
      fetchPolicy(fromDate, toDate);
      fetchExpenses(fromDate, toDate);
      fetchAllCallsData(fromDate, toDate);
      fetchMeetingsData(fromDate, toDate);
      fetchChartDataOfPolicyType(fromDate, toDate);
      fetchDueAmount(fromDate, toDate);
      fetchExitingPolicyCount(fromDate, toDate);
    }
  };


  useEffect(() => {
    fetchData();
  }, [selectedOption, startDate]);


  // lead api
  const fetchLead = async (startDate = '', endDate = '') => {
    let query = userRole === 'admin' ? `lead/list` : `lead/list/?createdBy=${userid}`;

    // Add startDate and endDate to the query if provided
    if (startDate || endDate) {
      query += `${userRole !== 'admin' ? '&' : '?'}startDate=${startDate}&endDate=${endDate}`;
    }

    const result = await apiget(query);

    if (result && result.status === 200) {
      setTotalLead({
        high: result?.data?.leadCountsData?.high || 0,
        mid: result?.data?.leadCountsData?.mid || 0,
        low: result?.data?.leadCountsData?.low || 0,
      });
    }
  };





  const fetchPolicy = async (startDate, endDate) => {
    let url = '';

    if (startDate && endDate) {

      url = userRole === 'admin'
        ? `policy/list?startDate=${startDate}&endDate=${endDate}`
        : `policy/list/?createdBy=${userid}&startDate=${startDate}&endDate=${endDate}`;
    } else {

      url = userRole === 'admin'
        ? `policy/list`
        : `policy/list/?createdBy=${userid}`;
    }

    const result = await apiget(url);



    if (result && result.status === 200) {
      // setTotalPolicy(result?.data?.total_recodes);
      setExpiryCount(result?.data?.bothCount?.expiryCount);
      setActivePolicy(result?.data?.bothCount?.activeCount);
      setRenewedPolicy(result?.data?.bothCount?.notRenewedCount);
      setDuePolicy(result?.data?.bothCount?.dueCount);

      // Amount totals
      setTotalPremiumDue(result?.data?.bothCount?.totalPremiumDue);
      setTotalPremiumNotRenewed(result?.data?.bothCount?.totalPremiumNotRenewed);
      setTotalPremiumExpired(result?.data?.bothCount?.totalPremiumExpired);
      setTotalPremiumActive(result?.data?.bothCount?.totalPremiumActive);
    }
  };


  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = '01';
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };


  const generateMonthLabels = (start, end) => {

    const labels = [];
    const current = new Date(start);
    const lastDate = new Date(end)

    // Loop through each month until we reach the end date
    while (current <= lastDate) {
      labels.push(formatDate(current));
      current.setMonth(current.getMonth() + 1); // Move to the next month
    }
    setChartLabelData(labels);
  };

  // Get the start and end date for the chart
  const getChartLabels = (startDate, currentDate) => {

    const defaultStart = new Date(new Date().setFullYear(new Date().getFullYear() - 1)); // 1 year ago
    const actualStartDate = startDate ? new Date(startDate) : defaultStart;
    const actualEndDate = currentDate ? new Date(currentDate) : new Date();
    generateMonthLabels(actualStartDate, actualEndDate);
  };



  const fetchChartDataOfPolicyType = async (startDate, endDate) => {
    try {
      // Build the query string based on startDate and endDate parameters
      let query = 'policy/policy-chart-data';

      // Append startDate and endDate if provided
      if (startDate && endDate) {
        query += `?startDate=${startDate}&endDate=${endDate}`;
      } else {
        // Get the current year dynamically and use it as the default year
        const currentYear = new Date().getFullYear();
        query += `?year=${currentYear}`;
      }

      const result = await apiget(query);

      // Check if the request was successful and set chart data
      if (result && result.status === 200) {
        setChartData(result?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };


  const fetchtasks = async () => {
    const result = await apiget('task/fetch-count');
    if (result && result.status === 200) {
      setTaskCount(result?.data)
    }
  }



  const fetchExpenses = async (startDate, endDate) => {
    try {
      // Build the query string based on startDate and endDate
      let query = '';
      if (startDate && endDate) {
        query = `?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`;
      }

      // Make API call with or without the query string
      const result = await apiget(`payment-method/calculate-data${query}`);

      if (result && result.status === 200) {
        setExpenses(result?.data);
      }
    } catch (error) {
      console.error("Error fetching expenses:", error);
    }
  };


  const fetchAllCallsData = async (startDate, endDate) => {
    try {
      // Build the query string conditionally based on the availability of startDate and endDate
      let query = '';
      if (startDate && endDate) {
        query = `?startDate=${startDate}&endDate=${endDate}`;
      }

      // Make the API request, including the query if available
      const result = await apiget(`call/get-calls${query}`);

      // If the request is successful, set the calls state with the returned data
      if (result && result.status === 200) {
        setCalls(result?.data);
      }
    } catch (error) {
      console.error('Error fetching call data:', error);
    }
  };

  const fetchMeetingsData = async (startDate, endDate) => {
    try {
      // Build query string if startDate and endDate are provided
      let query = '';

      if (startDate && endDate) {
        query = `?startDate=${startDate}&endDate=${endDate}`;
      }

      // Make the API call with the optional query string
      const result = await apiget(`meeting/get-meetings${query}`);

      // Check if the request was successful
      if (result && result.status === 200) {
        setMeetings(result?.data)
      }
    } catch (error) {
      console.error("Error fetching meetings data:", error);
    }
  };


  const fetchDueAmount = async (startDate, endDate) => {

    try {

      let query = '';

      if (startDate && endDate) {
        query = `?startDate=${startDate}&endDate=${endDate}`;
      }
      const result = await apiget(`payment-plan/dashboard-due-amount`);


      if (result && result.status === 200) {

        const duesPayments = result?.data?.filter((item) => item.status !== 'Paid');

        setDuePaymentPlan(duesPayments)
      }
    } catch (error) {
      console.error("Error fetching meetings data:", error);
    }

  }




  const fetchExitingPolicyCount = async (startDate, endDate) => {

    try {

      let query = '';

      if (startDate && endDate) {
        query = `?startDate=${startDate}&endDate=${endDate}`;
      }
      const result = await apiget(`contact/fetch-exsting-dashboard`);


      if (result && result.status === 200) {

      setExistingCount(result?.data)

      }
    } catch (error) {
      console.error("Error fetching meetings data:", error);
    }

  }


  useEffect(() => {
    fetchLead();
    fetchPolicy();
    fetchChartDataOfPolicyType();
    fetchtasks();
    fetchExpenses();
    fetchAllCallsData();
    fetchMeetingsData();
    getChartLabels();
    fetchDueAmount();
    fetchExitingPolicyCount();
  }, []);





  return (
    <>
      <Helmet>
        {/* <title> Dashboard | Minimal UI </title> */}
      </Helmet>

      <Container maxWidth="xl">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ mb: 5 }}>
            <Typography variant="h4" >
              Hello  Vikas ji
            </Typography>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <FormControl fullWidth sx={{ maxWidth: 200 }}>
                <Stack direction={'row'} alignItems={'center'} >
                  <Select

                    size='small'
                    fullWidth
                    value={selectedOption}
                    onChange={handleOptionChange}
                    displayEmpty
                  >
                    <MenuItem value="">Select Option</MenuItem>
                    <MenuItem value="Today" >Today</MenuItem>
                    <MenuItem value="Yesterday">Yesterday</MenuItem>
                    <MenuItem value="1 Week">Last 1 Week</MenuItem>
                    <MenuItem value="1 Month">Last 1 Month</MenuItem>
                    <MenuItem value="6 Months">Last 6 Months</MenuItem>
                    <MenuItem value="custom">Select from Calendar</MenuItem>
                  </Select>
                </Stack>

              </FormControl>

              {selectedOption === "custom" && (
                <Stack direction={'row'} alignItems={'center'}>
                  <TextField
                    type="date" // Specify the input type as date
                    size="small" // Set size to small
                    sx={{ width: '150px' }} // Adjust width as needed
                    InputLabelProps={{
                      shrink: true, // Ensures the label is shrunk for date input
                    }}
                    value={startDate ? startDate.toISOString().split("T")[0] : ''} // Format the date for input
                    onChange={(e) => {
                      setStartDate(new Date(e.target.value)); // Update state with the selected date
                    }}
                  />
                </Stack>
              )}
            </Stack>

          </Stack>
        </LocalizationProvider>

        <Grid container spacing={3}>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Due"
              total={duePolicy}
              icon={'ic:baseline-access-time'}
              amount={totalPremiumDue}
              onClick={() => navigate('/dashboard/policy/due')}
              sx={{
                cursor: 'pointer'
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Active"
              total={activePolicy}
              color="info"
              icon={'ic:baseline-check-circle'}
              amount={totalPremiumActive}
              onClick={() => navigate('/dashboard/policy/active')}
              sx={{
                cursor: 'pointer'
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Not Renewed"
              total={renewedPolicy}
              color="warning"
              icon={'ic:baseline-refresh'}
              amount={totalPremiumNotRenewed}
              onClick={() => navigate('/dashboard/policy/not-renewed')}
              sx={{
                cursor: 'pointer'
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Expired"
              total={expiryCount}
              color="error"
              icon={'ic:baseline-warning'}
              amount={totalPremiumExpired}
              onClick={() => navigate('/dashboard/policy/expired')}
              sx={{
                cursor: 'pointer'
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Leads"
              chartData={[
                { label: 'High', value: totalLead.high },
                { label: 'Medium', value: totalLead.mid },
                { label: 'Low', value: totalLead.low },
              ]}
              chartColors={[
                theme.palette.error.main,
                theme.palette.warning.main,
                theme.palette.success.main,

              ]}
            />
          </Grid>


          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Policies"
              // subheader="(+43%) than last year"
              // chartLabels={[
              //   '01/01/2003',

              // ]}
              chartLabels={chartLabelData}
              // chartData={[
              //   {
              //     name: chartData.policyType,
              //     type: 'column',
              //     fill: 'solid',
              //     data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 5],
              //   },

              // ]}
              chartData={chartData.map((item) => ({
                name: item.policyType || '',     // Set name as the policy type, or an empty string if undefined
                type: 'line',                  // Specify the chart type as 'column'
                fill: 'solid',                   // Set the fill type to 'solid'
                data: item.counts || [],         // Set data to the counts array, or an empty array if undefined
              }))}

            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} >
            {/* payment pay  */}
            <AppPaymentExpenses paymentMethods={expenses} title={'Pay'} type={'For Payment Pay'} />
          </Grid>

          <Grid item xs={12} sm={6} md={6} >
            {/*  payment recieve */}
            <AppPaymentExpenses paymentMethods={expenses} title={'Recieve'} type={'For Payment Recieve'} />
          </Grid>

          <Grid item xs={12} sm={6} md={6} >
            <AppTasks
              title="Tasks"
              list={taskCount.tasks || []}
              allcounts={taskCount.allCounts || {}}
              fetchtasks={fetchtasks}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} >
            <AppPaymentPlans paymentPlan={duePaymentPlan} />
          </Grid>
        
          <Grid item xs={12} sm={6} md={6} >
            <AppExistingPolicy existingPolicy={existingCount} />
          </Grid>

          <Grid item xs={12} md={6} lg={6} >
            <AppNewsUpdate
              title="Meetings"
              list={meetings}
              sx={{ height: '100%' }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6} sx={{ height: '100%' }}>
            <AppOrderTimeline
              title="Calls"
              list={calls}

            />
          </Grid>


          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>



          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>


        </Grid>
      </Container >
    </>
  );
}
