import JSPDF from "jspdf";
import "jspdf-autotable";
import formatNumber from "../../components/AmountFormatter";

const generatePDF = (data, share = false) => {
    // Create a new instance of jsPDF
    const Doc = new JSPDF(); 

    // Add a title to the PDF
    Doc.setFontSize(10);
    Doc.text("Payment Details", 14, 22);
    Doc.setLineWidth(100);

    // Define the table headers based on your columns
    const headers = [
        [
            "Insured Person Name",
            "Premium Amount (₹)",
            "Paid Amount (₹)",
            "Method",
            "Paid Date",
            "Discount (₹)",
            "Advance (₹)",
            "Due Date",
            "Due Amount (₹)",
            "Total (₹)",
        ],
    ];

    // Map your data rows to match the headers
    const rows = data.map((row) => [
        row.insuredPersonName || "--",
        row.premiumAmount ? formatNumber(row.premiumAmount) : "--",
        row.amount ? formatNumber(row.amount) : "--",
        row.paymentMethod || "--",
        row.date || "--",
        row.discount ? formatNumber(row.discount) : "--",
        row.advance ? formatNumber(row.advance) : "--",
        row.dueDate || "--",
        row.dueAmount ? formatNumber(row.dueAmount) : "--",
        row.total ? formatNumber(row.total) : "--",
    ]);

    // Add the table to the PDF
    Doc.autoTable({
        startY: 40,
        head: headers,
        body: rows,
        theme: "grid",
    });

    if (share) {
        const pdfBlob = Doc.output("blob");
        const pdfUrl = URL.createObjectURL(pdfBlob);
       
        const whatsappMessage = `Check out this payment details PDF :  ${pdfUrl}`;
        const whatsappLink = `https://wa.me/?text=${encodeURIComponent(whatsappMessage)}`;
      
        window.open(whatsappLink, "_blank");
    }
};

export default generatePDF;
