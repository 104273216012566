import React from 'react';
import { Card, Box, Typography, Divider, Table, TableHead, TableBody, TableRow, TableCell, TableContainer } from '@mui/material';
import formatNumber from '../../../components/AmountFormatter';

const AppPaymentExpenses = ({ paymentMethods = [], title, type }) => {
    const filteredMethods = paymentMethods.filter((method) => method.type === type);

    return (
        <Card>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
                <Typography variant="h6">{`Expenses (${title})`}</Typography>
            </Box>
            <Divider />
            <TableContainer >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ padding: '0 0 0 16px' }}>{type.includes("Recieve") ? 'Recieved By' : 'Paid By'}</TableCell>
                            <TableCell align="center" sx={{ padding: 1 }}>Amount</TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                <Box sx={{ height: 300, overflowY: 'auto' }}>
                    <Table>
                        <TableBody>
                            {filteredMethods.length > 0 ? (
                                filteredMethods.map((method) => (
                                    <TableRow key={method.paymentMethod}>
                                        <TableCell sx={{ padding: '0 0 0 16px' }}>
                                            {method.paymentMethod}
                                        </TableCell>
                                        <TableCell sx={{ padding: 1 }} align="center">
                                            {`₹ ${formatNumber(method.amount) || 0}`}
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={2} sx={{ textAlign: 'center' }}>
                                        <Typography variant="body2" color="textSecondary">
                                            No due available.
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Box>
            </TableContainer>
        </Card>
    );
};

export default AppPaymentExpenses;
