import { Box, Container, Grid, Stack, Tab, Tabs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Papa from "papaparse";
import Card from '@mui/material/Card';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Actionbutton from '../../components/Actionbutton'
import { apidelete, apiget } from '../../service/api';

import DeleteModel from '../../components/Deletemodle'
import Lead from './Lead';
import Claim from '../../components/claim/Claim';
import Notes from '../../components/note/Note';
import Calls from '../../components/call/Call';
import Meetings from '../../components/meeting/Meeting';
import Emails from '../../components/email/Email';
import Task from '../../components/task/Task'
import Header from '../../components/Header';
import Overview from './Overview';
import Moreinformation from './Moreinformation';
import Other from './Other';
import Policy from './Policy'
import FamilyTree from '../FamilyTree/FamilyTree'
import ExistingPolicy from '../policy/existingPolicy/ExistingPolicy';
import ContactDocument from './ContactDocument/ContactDocument';
import { CustomTabPanel, a11yProps } from '../../components/CustomTabPanel';
import AddContact from './Add'
import EditContact from './Edit'
import PaymentPlan from '../policy/PaymentPlain/PaymentPlain';

const View = () => {
    const userDataString = localStorage.getItem('user');
    const userData = JSON.parse(userDataString);
    const email = userData.emailAddress || '';
    const userRole = localStorage.getItem('userRole');


    const [contactData, setContactData] = useState({});
    const [userAction, setUserAction] = useState(null);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [opendelete, setOpendelete] = useState(false);
    const [value, setValue] = useState(0);
    const [isVisibleLead, setIsVisibleLead] = useState(false);
    const [isVisibleClaim, setIsVisibleClaim] = useState(false);
    const [isVisiblePolicy, setIsVisiblePolicy] = useState(false);
    const [isVisibleEvent, setIsVisibleEvent] = useState(false)
    const [isVisibleNotes, setIsVisibleNotes] = useState(false);
    const [isVisibleCall, setIsVisibleCall] = useState(false);
    const [isVisibleMeetings, setIsVisibleMeetings] = useState(false);
    const [isVisibleEmail, setIsVisibleEmail] = useState(false);
    const [isVisibleContact, setIsVisibleContact] = useState(false);
    const [isVisibleContactDoc, setIsVisibleContactDoc] = useState(false);
    const [isVisibleExistingPolicy, setIsVisibleExistingPolicy] = useState(false);
    const [isVisiblePaymentPlan, setIsVisiblePaymentPlan] = useState(false);
    const [searchID, setSearchID] = useState(null);
    const [contact, setContact] = useState({});

    const navigate = useNavigate()
    const params = useParams()

    // open add model
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // open Edit model
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);

    // open delete model
    const handleOpenDelete = () => setOpendelete(true);
    const handleCloseDelete = () => setOpendelete(false);
    const toggleVisibilityPaymentPlan = () => setIsVisiblePaymentPlan(!isVisiblePaymentPlan);

    // tab
    const handleChange = (event, newValue) => setValue(newValue);



    const fetchContactId = async () => {

        try {
            // Fetch contact data
            const contactData = await apiget(`contact/user-contact?email=${email}`);

            if (contactData?.data?.result.length !== 0) {
                setSearchID(contactData?.data?.result[0]._id);

            } else {

                try {
                    const fetchFamilyRoot = await apiget(`contact-relation/user-email?email=${email}`);

                    setSearchID(fetchFamilyRoot?.data?.result?.contact_id)

                } catch (error) {
                    console.log("Error fetching family root or user family data", error);
                }
            }
        } catch (error) {
            console.log("Error fetching contact data", error);
        }

    }


    // toggleButton
    const toggleVisibilityLead = () => setIsVisibleLead(!isVisibleLead);
    const toggleVisibilityEvent = () => setIsVisibleEvent(!isVisibleEvent)
    const toggleVisibilityClaim = () => setIsVisibleClaim(!isVisibleClaim);
    const toggleVisibilityPolicy = () => setIsVisiblePolicy(!isVisiblePolicy);
    const toggleVisibilityNotes = () => setIsVisibleNotes(!isVisibleNotes);
    const toggleVisibilityCall = () => setIsVisibleCall(!isVisibleCall);
    const toggleVisibilityMeeting = () => setIsVisibleMeetings(!isVisibleMeetings);
    const toggleVisibilityEmail = () => setIsVisibleEmail(!isVisibleEmail);
    const toggleVisibilityContact = () => setIsVisibleContact(!isVisibleContact);
    const toggleVisibilityContactDoc = () => setIsVisibleContactDoc(!isVisibleContactDoc);
    const toggleVisibilityExistingPolicy = () => setIsVisibleExistingPolicy(!isVisibleExistingPolicy);


    const back = () => {
        navigate('/dashboard/contact')
    }

    const fetchId = params.id || searchID;


    // fetch api
    const fetchdata = async () => {
        try {
            const result = await apiget(`contact/view/${fetchId}`);

            if (result && result.status === 200) {
                const fetchedData = result?.data[0];
                setContactData(fetchedData); // Update contactData state

                // Directly use fetchedData to update contact state
                setContact({
                    _id: fetchedData?._id,
                    firstName: fetchedData?.firstName || '',
                    lastName: fetchedData?.lastName || '',
                    phoneNumber: fetchedData?.phoneNumber || '',
                    emailAddress: fetchedData?.emailAddress || '',
                    aadhar: fetchedData?.aadhar || '',
                    pan: fetchedData?.pan || '',
                    dateOfBirth: fetchedData?.dateOfBirth || '',
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // delete api
    const deletedata = async () => {
        await apidelete(`contact/delete/${params.id}`)
        navigate('/dashboard/contact')
    }



    useEffect(() => {
        fetchContactId();
        if (params.id || searchID) {
            fetchdata();
        }
    }, [userAction, params.id, searchID])

    // Export data in csv file
    const contactCsvData = [
        {
            firstName: contactData?.firstName,
            lastName: contactData?.lastName,
            dateOfBirth: dayjs(contactData?.dateOfBirth).format('DD-MM-YYYY'),
            gender: contactData?.gender,
            phoneNumber: contactData?.phoneNumber,
            emailAddress: contactData?.emailAddress,
            address: contactData?.address,
            alternatePhoneNumber: contactData?.alternatePhoneNumber,
            additionalEmailAddress: contactData?.additionalEmailAddress,
            // instagramProfile: contactData?.instagramProfile,
            // twitterProfile: contactData?.twitterProfile,
            preferredContactMethod: contactData?.preferredContactMethod,
            referralSource: contactData?.referralSource,
            referralContactName: contactData?.referralContactName,
            relationshipToReferrer: contactData?.relationshipToReferrer,
            preferencesForMarketingCommunications: contactData?.preferencesForMarketingCommunications,
            preferredLanguage: contactData?.preferredLanguage,
            createdOn: dayjs(contactData?.createdOn).format('DD-MM-YYYY HH:mm:ss'),
            modifiedOn: dayjs(contactData?.modifiedOn).format('DD-MM-YYYY HH:mm:ss'),
        },
    ];

    const handleExport = () => {
        const csvString = Papa.unparse(contactCsvData);
        const csvBlob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
        const csvUrl = URL.createObjectURL(csvBlob);
        const downloadLink = document.createElement("a");

        downloadLink.href = csvUrl;
        downloadLink.setAttribute(
            "download",
            `${contactData?.firstName} ${contactData?.lastName} Contact_Data.csv`
        );
        downloadLink.click();

        // handleCloseaction();
    };

    return (
        <>
            {/* Add Contact Model */}
            <AddContact open={open} handleClose={handleClose} setUserAction={setUserAction} />

            {/* Add Edit Model */}
            <EditContact open={openEdit} handleClose={handleCloseEdit} id={params.id} fetchContact={fetchdata} />

            {/* open Delete Model */}
            <DeleteModel opendelete={opendelete} handleClosedelete={handleCloseDelete} deletedata={deletedata} id={params.id} />

            <Container>
                {userRole === 'admin' && <Grid container display="flex" alignItems="center">
                    <Stack direction="row" alignItems="center" mb={3} justifyContent={"space-between"} width={"100%"}>
                        <Header
                            title={contactData.firstName ? `${contactData?.firstName} ${contactData?.lastName ? contactData?.lastName : ''}` : 'loading...'}
                            subtitle="Contact Details"
                        />
                        <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
                            {/* Action Butoon */}
                            <Actionbutton
                                handleOpen={handleOpen}
                                handleOpenEdit={handleOpenEdit}
                                handleOpenDelete={handleOpenDelete}
                                handleExport={handleExport}
                                back={back}
                            />
                        </Stack>
                    </Stack>
                </Grid>}

                {userRole === 'admin' && <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: "0px" }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="OVERVIEW" {...a11yProps(0)} />
                            <Tab label="MORE INFORMATION" {...a11yProps(1)} />
                            <Tab label="OTHER" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Overview data={contactData} setUserAction={setUserAction} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Moreinformation data={contactData} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <Other data={contactData} />
                    </CustomTabPanel>
                </Box>}


                {/* Policy Table */}
                {userRole === 'admin' && <Card sx={{ marginTop: "50px" }}>
                    <Policy toggleVisibilityPolicy={toggleVisibilityPolicy} isVisiblePolicy={isVisiblePolicy} rows={contactData?.policies} contact={contact} familyData={contactData?.familytrees} setUserAction={setUserAction} _id={params.id} />
                </Card>}
                <Card sx={{ marginTop: "20px" }}>
                    <FamilyTree toggleVisibilityContact={toggleVisibilityContact} isVisibleContact={isVisibleContact} rows={contactData?.familytrees} setUserAction={setUserAction} _id={params.id} />
                </Card>

                <Card sx={{ marginTop: "20px" }}>
                    <ContactDocument toggleVisibilityContactDoc={toggleVisibilityContactDoc} isVisibleContactDoc={isVisibleContactDoc} rows={contactData?.contactdocuments} setUserAction={setUserAction} _id={params.id} />
                </Card>
                <Card sx={{ marginTop: "20px" }}>
                    <ExistingPolicy
                        toggleVisibilityExistingPolicy={toggleVisibilityExistingPolicy} userAction={userAction} rows={contactData?.existingpolicies} setUserAction={setUserAction} isVisibleExistingPolicy={isVisibleExistingPolicy} _id={params.id}
                    />
                </Card>

                <Card sx={{ marginTop: "20px" }}>
                    <PaymentPlan toggleVisibilityPaymentPlan={toggleVisibilityPaymentPlan} isVisiblePaymentPlan={isVisiblePaymentPlan} rows={contactData?.paymentPlans} _id={params.id} setUserAction={setUserAction} />
                </Card>

                {/* Claim Table */}
                <Card sx={{ marginTop: "50px" }}>
                    <Claim toggleVisibilityClaim={toggleVisibilityClaim} isVisibleClaim={isVisibleClaim} rows={contactData?.claims} setUserAction={setUserAction} _id={params.id} />
                </Card>

                {/* Lead Table */}



                {/* Notes Table */}
                <Card sx={{ marginTop: "20px" }}>
                    <Notes toggleVisibilityNotes={toggleVisibilityNotes} isVisibleNotes={isVisibleNotes} rows={contactData?.notes} setUserAction={setUserAction} _id={params.id} />
                </Card>
                {userRole === 'admin' && <>
                    <Card sx={{ marginTop: "20px" }}>
                        <Lead toggleVisibilityLead={toggleVisibilityLead} isVisibleLead={isVisibleLead} rows={contactData?.leads} setUserAction={setUserAction} _id={params.id} />
                    </Card>
                    {/* Task Table */}
                    <Card sx={{ marginTop: "20px" }}>
                        <Task toggleVisibilityTask={toggleVisibilityEvent} isVisibleTask={isVisibleEvent} rows={contactData?.tasks} setUserAction={setUserAction} _id={params.id} />
                    </Card>

                    {/* Meetings Table */}
                    <Card sx={{ marginTop: "20px" }}>
                        <Meetings toggleVisibilityMeeting={toggleVisibilityMeeting} isVisibleMeetings={isVisibleMeetings} rows={contactData?.meetings} setUserAction={setUserAction} _id={params.id} data={contactData} />
                    </Card>

                    {/* Calls Table */}
                    <Card sx={{ marginTop: "20px" }}>
                        <Calls toggleVisibilityCall={toggleVisibilityCall} isVisibleCall={isVisibleCall} rows={contactData?.calls} setUserAction={setUserAction} _id={params.id} />
                    </Card>

                    {/* Emails Table */}
                    <Card sx={{ marginTop: "20px" }}>
                        <Emails toggleVisibilityEmail={toggleVisibilityEmail} isVisibleEmail={isVisibleEmail} rows={contactData?.emails} setUserAction={setUserAction} _id={params.id} data={contactData} />
                    </Card>
                </>}
            </Container>


        </ >
    )
}

export default View
