import React from 'react';
import {
    Card,
    Box,
    Typography,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Link,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import formatDate from '../../../components/DateFormatter'

const AppPaymentPlans = ({ paymentPlan = [] }) => {
    const navigate = useNavigate();

    const handleNavigation = (id) => {
        navigate(`/dashboard/recieved-payment/view/${id}`);
    };

 

    return (
        <Card>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
                <Typography variant="h6">Due Payments</Typography>
            </Box>
            <Divider />
            <TableContainer sx={{ height: 300 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ padding: '0 0 0 16px' }}>
                                <Typography variant="subtitle2">
                                    Name
                                </Typography>
                            </TableCell>

                            <TableCell sx={{ padding: 1 }} >
                                <Typography textAlign={'center'} variant="subtitle2">Amount (₹)</Typography>
                            </TableCell>
                            <TableCell sx={{ padding: 1 }} >
                                <Typography textAlign={'center'} variant="subtitle2">Due Date</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {paymentPlan.length > 0 ? (
                            paymentPlan.map((due) => (
                                <TableRow key={due._id} hover>
                                    <TableCell sx={{ padding: '0 0 0 16px' }}>
                                        <Link
                                            component="button"
                                            variant="body2"
                                            onClick={() => handleNavigation(due.contact_id)}
                                            sx={{ textDecoration: 'underline', textTransform: 'capitalize' }}
                                        >
                                            {`${due?.contactInfo[0]?.firstName} ${due?.contactInfo[0]?.lastName}`}
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ textAlign: 'center', padding: 1 }} >{due.dueAmount}</TableCell>
                                    <TableCell sx={{ textAlign: 'center', padding: 1 }} >
                                        {formatDate(due.dueDate)}
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={3} sx={{ textAlign: 'center', padding: 2 }}>
                                    <Typography variant="body2" color="textSecondary">
                                        No due available.
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
};

export default AppPaymentPlans;
