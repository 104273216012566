// @mui
import PropTypes from 'prop-types';
import { Box, Stack, Link, Card, Button, Divider, Typography, CardHeader } from '@mui/material';

import moment from 'moment'
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

// ----------------------------------------------------------------------

AppNewsUpdate.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppNewsUpdate({ title, list, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={
        <Stack direction="row" justifyContent="space-between">
          {title}
          <span style={{ fontSize: '0.8rem', color: '#000', display: 'flex', gap: '2vw' }}>
            <span style={{ display: 'flex', alignItems: 'center', gap: '0.5vw' }}>
              <Box
                component={'span'}
                sx={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: 'success.main', // Success color for "Held"
                  borderRadius: '50%',
                  display: 'inline-block',
                }}
              />
              {`Held`}
            </span>

            <span style={{ display: 'flex', alignItems: 'center', gap: '0.5vw' }}>
              <Box
                component={'span'}
                sx={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: 'warning.main', // Warning color for "Planned"
                  borderRadius: '50%',
                  display: 'inline-block',
                }}
              />
              {`Planned`}
            </span>

            <span style={{ display: 'flex', alignItems: 'center', gap: '0.5vw' }}>
              <Box
                component={'span'}
                sx={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: 'error.main', // Error color for "Not Held"
                  borderRadius: '50%',
                  display: 'inline-block',
                }}
              />
              {`Not Held`}
            </span>
          </span>
        </Stack>
      } subheader={
        <Stack direction={'row'} spacing={2}>
          <Typography variant='body2' color="text.secondary">Lead (L)</Typography>
          <Typography variant='body2' color="text.secondary">Contact (C)</Typography>
        </Stack>
      } />

      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {list.map((item) => (
            <NewsItem key={item._id} item={item} />
          ))}
        </Stack>
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button size="small" color="inherit" endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}>
          View all
        </Button>
      </Box>
    </Card>
  );
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  meetings: PropTypes.shape({
    _id: PropTypes.string.isRequired,              // ID of the meeting
    subject: PropTypes.string.isRequired,          // Subject or title of the meeting
    status: PropTypes.string.isRequired,           // Status of the meeting (e.g., "Planned", "Held", etc.)
    startDate: PropTypes.instanceOf(Date),     // The start date and time of the meeting
    duration: PropTypes.string,                    // Duration of the meeting
    relatedTo: PropTypes.string,                   // Related to (could be a Lead, Account, etc.)       
  }),
};


function NewsItem({ item }) {

  const { subject, status, startDate, duration, relatedTo } = item;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box
        component="span"
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        alt={relatedTo}
        fontWeight={600}
        sx={{
          width: 40,
          height: 40,
          borderRadius: '50%',
          flexShrink: 0,
          backgroundColor:
            status === 'Held' ? 'success.main' :
              status === 'Planned' ? 'warning.main' :
                'error.main', // Error color for "Not Held"
        }}
      >
        {relatedTo === 'Lead' ? 'L' : 'C'}
      </Box>


      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
        <Link color="inherit" variant="body1" underline="hover" noWrap>
          {subject}
        </Link>

        <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '12px' }} noWrap>
          {moment(startDate).format('lll')}
        </Typography>
      </Box>

      <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
        {`duration : ${duration}`}
      </Typography>
    </Stack>
  );
}


// import PropTypes from 'prop-types';
// import { Box, Card, Button, Divider, Typography, CardHeader, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Stack } from '@mui/material';
// import moment from 'moment';
// import Iconify from '../../../components/iconify';

// // ----------------------------------------------------------------------

// AppNewsUpdate.propTypes = {
//   title: PropTypes.string,
//   subheader: PropTypes.string,
//   list: PropTypes.array.isRequired,
// };

// export default function AppNewsUpdate({ title, list, ...other }) {
//   return (
//     <Card {...other}>
//       <CardHeader
//         title={
//           <Stack direction="row" justifyContent="space-between">
//             {title}
//             <span style={{ fontSize: '0.8rem', color: '#000', display: 'flex', gap: '2vw' }}>
//               <span style={{ display: 'flex', alignItems: 'center', gap: '0.5vw' }}>
//                 <Box
//                   component={'span'}
//                   sx={{
//                     width: '10px',
//                     height: '10px',
//                     backgroundColor: 'success.main', // Success color for "Held"
//                     borderRadius: '50%',
//                     display: 'inline-block',
//                   }}
//                 />
//                 {`Held`}
//               </span>

//               <span style={{ display: 'flex', alignItems: 'center', gap: '0.5vw' }}>
//                 <Box
//                   component={'span'}
//                   sx={{
//                     width: '10px',
//                     height: '10px',
//                     backgroundColor: 'warning.main', // Warning color for "Planned"
//                     borderRadius: '50%',
//                     display: 'inline-block',
//                   }}
//                 />
//                 {`Planned`}
//               </span>

//               <span style={{ display: 'flex', alignItems: 'center', gap: '0.5vw' }}>
//                 <Box
//                   component={'span'}
//                   sx={{
//                     width: '10px',
//                     height: '10px',
//                     backgroundColor: 'error.main', // Error color for "Not Held"
//                     borderRadius: '50%',
//                     display: 'inline-block',
//                   }}
//                 />
//                 {`Not Held`}
//               </span>
//             </span>
//           </Stack>
//         }
//         subheader={
//           <Stack direction={'row'} spacing={2}>
//             <Typography variant="body2" color="text.secondary">Lead (L)</Typography>
//             <Typography variant="body2" color="text.secondary">Contact (C)</Typography>
//           </Stack>
//         }
//       />

//       <TableContainer>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>Status</TableCell>
//               <TableCell>Subject</TableCell>
//               <TableCell>Start Date</TableCell>
//               <TableCell>Duration</TableCell>
//               <TableCell>Related To</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {list.map((item) => (
//               <TableRow key={item._id}>
//                 <TableCell>
//                   <Box
//                     sx={{
//                       width: 16,
//                       height: 16,
//                       borderRadius: '50%',
//                       display: 'inline-block',
//                       backgroundColor:
//                         item.status === 'Held' ? 'success.main' :
//                         item.status === 'Planned' ? 'warning.main' :
//                         'error.main',
//                     }}
//                   />
//                 </TableCell>
//                 <TableCell>
//                   <Typography noWrap>{item.subject}</Typography>
//                 </TableCell>
//                 <TableCell>
//                   <Typography noWrap>{moment(item.startDate).format('lll')}</Typography>
//                 </TableCell>
//                 <TableCell>
//                   <Typography>{item.duration}</Typography>
//                 </TableCell>
//                 <TableCell>
//                   <Box
//                     component="span"
//                     sx={{
//                       fontWeight: 600,
//                       display: 'inline-block',
//                       width: 24,
//                       height: 24,
//                       lineHeight: '24px',
//                       textAlign: 'center',
//                       borderRadius: '50%',
//                       backgroundColor: item.relatedTo === 'Lead' ? 'primary.main' : 'secondary.main',
//                       color: '#fff',
//                     }}
//                   >
//                     {item.relatedTo === 'Lead' ? 'L' : 'C'}
//                   </Box>
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>

//       <Divider />

//       <Box sx={{ p: 2, textAlign: 'right' }}>
//         <Button size="small" color="inherit" endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}>
//           View all
//         </Button>
//       </Box>
//     </Card>
//   );
// }

