import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import User from './pages/user/User'
import UserView from './pages/user/View'
import Lead from './pages/Lead/Lead';
import DashboardAppPage from './pages/DashboardAppPage';
import LeadView from './pages/Lead/View'
import Contact from './pages/contact/Contact';
import ContactView from './pages/contact/View'
import Policy from './pages/policy/Policy'
import PolicyView from './pages/policy/View'
import Calendar from './pages/Calendar/Calendar';
import Document from './pages/documents/Documents';
import Calls from './pages/calls/Call';
import CallsView from './pages/calls/View'
import Meeting from './pages/meeting/Meeting';
import MeetingView from './pages/meeting/View'
import Email from './pages/email/Email'
import EmailView from './pages/email/View'
import Task from './pages/task/Task';
import TaskView from './pages/task/View'
import EmailTemplate from './pages/emailTemplate/EmailTemplate';
import AddEmailTemplate from './pages/emailTemplate/Add'
import ViewEmailTemplate from './pages/emailTemplate/View'
import PolicyCompany from './pages/policy/PolicyEditor/PolicyCompanies/PolicyCompany';
import PolicyType from './pages/policy/PolicyEditor/PolicyType/PolicyType';

import BlogManagemnet from './pages/BlogManagement/Blog'
import Achievements from './pages/Achievement_Management/Achievement';
import Enquiry from './pages/Enquiry_Management/Enquiry';
import Testimonial from './pages/TestimonialManagement/Testimonials';
import AboutUs from './pages/AboutUs_Management/AboutUs';
import InsurancePackage from './pages/InsurancePackageManagement/InsurancePackage';
import ListedCompany from './pages/Listed_Companies_Management/ListedCompany';
import Article from './pages/ArticleManagement/Article'
import TeamManagement from './pages/Team_Management/Team_Management';
import PaymentSource from './pages/PaymentSource/PaymentSource';
import PaymentMethod from './pages/PaymentMethod/PaymentMethod'
import HealthInsurancePlans from './pages/InsuranceManagement/HealthInsurance/InsurancePlans/InsurancePlans'
import ServicePlans from './pages/InsuranceManagement/ServicePlans/ServicePlans'
import HelpCenter from './pages/HelpCentreManagement/HelpCentre'
import PaymentOptions from './pages/PaymentOptions/PaymentOption'
import RecievedPayments from './pages/RecievedPayments/RecievedPayment';
import ViewPayment from './pages/RecievedPayments/ViewPayment';
import RankingManager from './pages/RankingManager/RankingManager';
import ReferralSource from './pages/ReferralSource/ReferralSource';
import RelationShipManagement from './pages/RelationShipManagement/RelationShipManagement';
import Commitment from './pages/HomePages/Achievements/Commitment/Commitment';
import PaymentPlan from './pages/policy/PaymentPlain/PaymentPlain';




export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <User /> },
        { path: 'user/view/:id', element: <UserView /> },
        { path: 'lead', element: <Lead /> },
        { path: 'lead/view/:id', element: <LeadView /> },
        { path: 'contact', element: <Contact /> },
        { path: 'contact/view/:id', element: <ContactView /> },
        { path: 'policy', element: <Policy /> },
        { path: 'policy/:type', element: <Policy /> },
        { path: 'policy/:type', element: <Policy /> },
        { path: 'policy/:type', element: <Policy /> },
        { path: 'policy/:type', element: <Policy /> },
        { path: 'policy/view/:id', element: <PolicyView /> },
        { path: 'calendar', element: <Calendar /> },
        { path: 'document', element: <Document /> },

        { path: 'call', element: <Calls /> },
        { path: 'call/view/:id', element: <CallsView /> },
        { path: 'meeting', element: <Meeting /> },
        { path: 'meeting/view/:id', element: <MeetingView /> },
        { path: 'email', element: <Email /> },
        { path: 'email/view/:id', element: <EmailView /> },
        { path: 'task', element: <Task /> },
        { path: 'task/view/:id', element: <TaskView /> },
        { path: 'emailtemplate', element: <EmailTemplate /> },
        { path: 'emailtemplate/add', element: <AddEmailTemplate /> },
        { path: 'emailtemplate/view/:id', element: <ViewEmailTemplate /> },
        { path: 'policy-types', element: <PolicyType /> },
        { path: 'policy-company', element: <PolicyCompany /> },
        { path: 'blog-management', element: <BlogManagemnet /> },
        { path: 'achievement-management', element: <Achievements /> },
        { path: 'enquiry', element: <Enquiry /> },
        { path: 'testimonial', element: <Testimonial /> },
        { path: 'about', element: <AboutUs /> },
        { path: 'insurance_package', element: <InsurancePackage /> },
        { path: 'listed_company', element: <ListedCompany /> },
        { path: 'article', element: <Article /> },
        { path: 'team', element: <TeamManagement /> },
        { path: 'payment-source', element: <PaymentSource /> },
        { path: 'payment-method', element: <PaymentMethod /> },
        { path: 'insurance-plans', element: <HealthInsurancePlans /> },
        { path: 'service-plans', element: <ServicePlans /> },
        { path: 'help-center', element: <HelpCenter /> },
        { path: 'payment-option', element: <PaymentOptions /> },
        { path: 'recieved-payment', element: <RecievedPayments /> },
        { path: 'recieved-payment/view/:id', element: <ViewPayment /> },
        { path: 'ranking', element: <RankingManager /> },
        { path: 'referral-source', element: <ReferralSource /> },
        { path: 'relationship', element: <RelationShipManagement /> },
        { path: 'commitment', element: <Commitment /> },
        { path: 'commitment', element: <Commitment /> },
        { path: 'payment-plan', element: <PaymentPlan /> },


        // { path: 'history', element: <History /> },

        // { path: '*', element: <Navigate to="/dashboard/app" /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: '/dashboard', element: <Navigate to="/dashboard/app" />, index: true },
        // { path: '404', element: <Page404 /> },
        // { path: '*', element: <Navigate to="/404" /> },
      ],
    },

    // {
    //   path: '*',
    //   element: <Navigate to="/404" replace />,
    // },
  ]);

  return routes;
}
